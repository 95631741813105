import * as React from "react"
import Layout from "../../components/layout"
import '../../style.css'
import Star from "../../components/star";
import DownloadButton from "../../components/downloadButton";
import ExternalButton from "../../components/externalButton";
import InternalButton from "../../components/internalButton";

const BHRA = () => {

  const ButtonStylesClass = 'BHRA-item-buttons'
  const folder = '/downloads/resources/BHRA'

  return (
    <Layout>
        <div>
          <h1>Fermi Telescope Black Hole Resource Area</h1>
          <Star />
          <div>
            <div>
              <h2 id = '1'style={{marginBottom:'1em'}}>PBS/NOVA Television Show: Monster of the Milky Way</h2>
              
              <div style={{float:'left', marginRight:'1em'}}>
               <img src="/whirly.jpg" alt='Black Hole'/>
                <p style={{maxWidth:'206px'}}>The Milky Way's light distorted at the event horizon of a black hole</p>
              </div>
              
              <p style={{textAlign:'start'}}>Developed by <a href="http://www.tlproductions.com/" target="_blank" rel="noreferrer">Thomas Lucas Productions</a>, this 1- hour show for <a href="https://www.pbs.org/wgbh/nova/" target="_blank" rel="noreferrer">PBS/NOVA</a> entitled <a href="https://www.pbs.org/wgbh/nova/video/monster-of-the-milky-way/" target="_blank" rel="noreferrer">"Monster of the Milky Way"</a> takes a sweeping look at the many lives of black holes. From studies of the supermassive black hole in the center of our Milky Way Galaxy to the birth of black holes in gamma-ray bursts and their seeming ubiquity in the universe, <a href="https://www.nasa.gov/" target="_blank" rel="noreferrer">NASA</a> and <a href="https://www.nsf.gov/" target="_blank" rel="noreferrer">NSF</a> funds provided support for this exciting hour of high-definition television. Thomas Lucas Productions has extensive experience in developing astronomy shows for public television: they produced <a href="http://www.pbs.org/deepspace/" target="_blank" rel="noreferrer">Mysteries of Deep Space</a> (which featured the Hubble Space Telescope), Voyage to the Milky Way, and the NSF-co-sponsored documentary entitled <a href="https://www.pbs.org/wgbh/nova/universe/" target="_blank" rel="noreferrer">Runaway Universe</a> (which is about dark energy and the cosmological constant).</p>
            </div>

            <div style={{marginTop:'3em'}}>
              <h2 style={{marginBottom:'1em'}}>Denver Museum of Nature and Science Planetarium Show: "Black Holes: The Other Side Of Infinity"</h2>
              <p style={{textAlign:'start'}}>This large format planetarium show, <a href="https://www.dmns.org/visit/planetarium/black-holes/" target="_blank" rel="noreferrer">"Black Holes: The Other Side Of Infinity"</a> premiered in January 2006 at the <a href="https://www.dmns.org/" target="_blank" rel="noreferrer">Denver Museum of Nature and Science</a> (DMNS) The program was produced at DMNS with Tom Lucas and the NASA E/PO Group. Dr. Lynn Cominsky served as Science Director, and Dr. Philip Plait was the Science Consultant. An accompanying set of classroom exercises has been developed at DMNS for educators to use when their classes see the planetarium show.</p>
            </div>

            <div>
              <h2 style={{marginBottom:'1em'}}>Black Holes FAQ Sheet: From Here to Infinity</h2>
              
              <h3 style={{marginBottom:'1em'}}>English PDFs</h3>
              <div className='BHRA-button-container'>
                <DownloadButton text='Full Color PDF' link={`${folder}/BFfactsheet09wide.pdf`} buttonStyles = {ButtonStylesClass}/>
                <DownloadButton text='Printable PDF' link={`${folder}/BHfactprintuniv09_508.pdf`} buttonStyles = {ButtonStylesClass}/>
              </div>

              <h3 style={{marginBottom:'1em'}}>Spanish PDFs</h3>
              <div className='BHRA-button-container'>
                <DownloadButton text='Full Color PDF' link={`${folder}/BFfactsheet06span.pdf`} buttonStyles = {ButtonStylesClass}/>
                <DownloadButton text='Printable PDF' link={`${folder}/BHfactprintunivspan.pdf`} buttonStyles = {ButtonStylesClass}/>
              </div>
            </div>

            <div style={{marginTop:'3em'}}>
              <h2 style={{marginBottom:'1em'}}>Black Holes Educator Workshop: From Here to Infinity</h2>
              <p style={{textAlign:'start'}}>The SSU group, in collaboration with DMNS, has created an educators' workshop based on the program, complete with an educators' guide with well-tested activities from different Astrophysics division missions to teach the basic concepts outlined in the planetarium program.</p>
              
              <div className='BHRA-button-container'>
                <DownloadButton text='Draft Guide' link={`${folder}/bhguide06.pdf`} buttonStyles = {ButtonStylesClass}/>
                <DownloadButton text='Accessible Guide' link={`${folder}/bhguide06_508.pdf`} buttonStyles = {ButtonStylesClass}/>
              </div>
              <div>
                <h3 style={{marginBottom:'1em'}}>Guide Presentations (PPTs)</h3>
                <div className='BHRA-button-container'>
                  {/* These three need to be changed to powerpoint viewer things, do this later */}
                  <InternalButton text='The Formation of Black Holes' link='/ppt/bh_otherside' buttonStyles = {ButtonStylesClass}/>
                  <InternalButton text='The gravity of the situation (around black holes)' link='/ppt/bh_gravity' buttonStyles = {ButtonStylesClass}/>
                  <InternalButton text='Inside the Black Hole at the Center of the Milky Way.' link='/ppt/bh_travel' buttonStyles = {ButtonStylesClass}/>
                  <InternalButton text='The Search for Black Holes.' link='/ppt/bh_search' buttonStyles = {ButtonStylesClass}/>
                </div>
              </div>
            </div>

            <div style={{marginTop:'3em'}}>
              <h2 style={{marginBottom:'1em'}}>Black hole science web sites</h2>
              <p style={{textAlign:'start'}}> The following websites have excellent scientific descriptions of black holes, designed for curious minds of all ages.</p>
              <h3 style={{marginBottom:'1em'}}>For young readers</h3>
                <div className='BHRA-button-container'>
                  <ExternalButton text='StarChild: Black Holes' link='http://starchild.gsfc.nasa.gov/docs/StarChild/universe_level1/black_holes.html' buttonStyles={ButtonStylesClass}/>
                  <ExternalButton text='Imagine The Universe!' link='https://imagine.gsfc.nasa.gov/science/index.html' buttonStyles={ButtonStylesClass}/>
                  <ExternalButton text='Amazing Space' link='https://hubblesite.org/resource-gallery/learning-resources/amazing-space.html' buttonStyles={ButtonStylesClass}/>
                  <ExternalButton text='Space Place' link='http://spaceplace.nasa.gov/black-holes/en/' buttonStyles={ButtonStylesClass}/>
                  <ExternalButton text='What Is a Black Hole? (K-4)' link='https://www.nasa.gov/audience/forstudents/k-4/stories/nasa-knows/what-is-a-black-hole-k4.html' buttonStyles={ButtonStylesClass}/>
                  <ExternalButton text='What Is a Black Hole? (5-8)' link='https://www.nasa.gov/audience/forstudents/5-8/features/nasa-knows/what-is-a-black-hole-58.html' buttonStyles={ButtonStylesClass}/>
                </div>
              <h3 style={{marginBottom:'1em'}}>For average adult readers</h3>
                <div className='BHRA-button-container'>
                  <ExternalButton text='Chandra:Blackholes' link='https://chandra.harvard.edu/xray_sources/blackholes.html' buttonStyles={ButtonStylesClass}/>
                  <ExternalButton text='Chandra:Quasars' link='https://chandra.harvard.edu/xray_sources/quasars.html' buttonStyles={ButtonStylesClass}/>
                  <ExternalButton text='Hubblesite' link='https://hubblesite.org/news/news-releases' buttonStyles={ButtonStylesClass}/>
                </div>
                <h3 style={{marginBottom:'1em'}}>For advanced readers</h3>
                  <div className='BHRA-button-container'>
                    <ExternalButton text='Andrew Hamilton' link='https://jila.colorado.edu/~ajsh/' buttonStyles={ButtonStylesClass}/>
                  </div>
            </div>

            <div style={{marginTop:'3em'}}>
              <h2 style={{marginBottom:'1em'}}>Black hole activities for the classroom</h2>
              <p style={{textAlign:'start'}}>Black holes are an excellent way to engage your student and increase their curiosity and wonder of the world around them. Here are some sites that have classroom activities which explore the concepts of how black holes behave, and teach basic concepts in science and math.</p>
              <div className='BHRA-button-container'>
                <ExternalButton text="Educator's Corner" link='https://imagine.gsfc.nasa.gov/educators/index.html' buttonStyles={ButtonStylesClass}/>
                <ExternalButton text="Gravity Probe-B" link='http://einstein.stanford.edu/RESOURCES/education-index.html#guide' buttonStyles={ButtonStylesClass}/>
                <ExternalButton text="HubbleSite : Amazing Space" link='https://hubblesite.org/resource-gallery/learning-resources/amazing-space.html' buttonStyles={ButtonStylesClass}/>
                <ExternalButton text="Gamma Ray Burst Educator Unit" link='https://swift.sonoma.edu/education/index.html#grb' buttonStyles={ButtonStylesClass}/>
              </div>
            </div>

            <div style={{marginTop:'3em'}}>
              <h2 style={{marginBottom:'1em'}}>Cool black hole games for all ages</h2>
              <p style={{textAlign:'start'}}>What better way to treat the endpoints of all matter, space, and time, than with a game? These websites features games - both online and board/card games - that are not only fun to play, but are educational and standards-based</p>
              <div className='BHRA-button-container'>
                <ExternalButton text='Space Place' link='http://spaceplace.nasa.gov/black-holes/en/' buttonStyles={ButtonStylesClass}/>
                {/* Add a link button to the fermi race car game */}
                <ExternalButton text='Black Hole Explorer Game' link='https://lweb.cfa.harvard.edu/seuforum/einstein/resource_BHExplorer.htm' buttonStyles={ButtonStylesClass}/>
                <ExternalButton text='Journey to a Black Hole' link='https://lweb.cfa.harvard.edu/seuforum/einstein/resource_journeyblackhole.htm' buttonStyles={ButtonStylesClass}/>
                <ExternalButton text="Inside Einstein's Universe" link='https://lweb.cfa.harvard.edu/seuforum/einstein/resources_visual.htm' buttonStyles={ButtonStylesClass}/>

              </div>
            </div>

            <div style={{marginTop:'3em'}}>
              <h2 style={{marginBottom:'1em'}}>Great black hole resource pages</h2>
              <p style={{textAlign:'start'}}>There are many more great sites on the web dealing with black holes, how they work, how they affect their neighborhoods, and what scientists hope to learn from them. Listed below are a few of these places where you can learn even more about black holes</p>
              <div className='BHRA-button-container'>
                <ExternalButton text="ASP : Atronomical Society of the Pacific" link='https://astrosociety.org/education-outreach/programs.html' buttonStyles={ButtonStylesClass}/>
                <ExternalButton text="Black Hole FAQ : Harvard" link='https://apod.nasa.gov/htmltest/gifcity/bh_pub_faq.html' buttonStyles={ButtonStylesClass}/>
                <ExternalButton text="Black Hole FAQ : Virginia Tech" link='http://www1.phys.vt.edu/~jhs/faq/blackholes.html' buttonStyles={ButtonStylesClass}/>
                <ExternalButton text='Black Hole FAQ : Chandra' link='https://chandra.harvard.edu/resources/faq/black_hole/bhole-main.html' buttonStyles={ButtonStylesClass}/>
                <ExternalButton text='Everything you need to know about BLACK HOLES' link='https://www.astro.keele.ac.uk/workx/blackholes/index3.html' buttonStyles={ButtonStylesClass}/>
                <ExternalButton text='Hubblesite' link='https://hubblesite.org/news/news-releases' buttonStyles={ButtonStylesClass}/>
              </div>
            </div>

          </div>
        </div>
    </Layout>
  )
}

export default BHRA